/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect} from 'react';
import { Box, List, ListItem, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchSites } from '../utils/api';

interface ConstructionSite {
    id: number;
    name: string;
}

const SiteSelect: React.FC = () => {
    const navigate = useNavigate();

    // eslint-disable-line @typescript-eslint/no-unused-vars
    const [siteList, setSiteList] = useState<ConstructionSite[]>([
        {'id': 3, 'name': 'Handeingabe'},
        {'id': 5, 'name': 'Bauhof'},
        {'id': 6, 'name': 'Werkstatt'},
    ]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    
    useEffect(() => {
        fetchSites()
          .then(data => setSiteList(data))
          .catch(error => {
            navigate('/login')
            console.error('Error:', error)
        });
      }, []);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleSiteSelect = (site: ConstructionSite) => {
        if (site.id === 3) {
            const siteName = window.prompt('Bitte den Baustellennamen eingeben:');
            if (siteName) {
                navigate(`/scan?site=${encodeURIComponent(site.id)}&name=${encodeURIComponent(siteName)}`);
            }
        } else {
            navigate(`/scan?site=${encodeURIComponent(site.id)}&name=${encodeURIComponent(site.name)}`);
        }
    };

    return (
        <Box  width="100%">
            <TextField size="small" style={{ width: '100%' }} value={searchTerm} onChange={handleSearch} placeholder="Baustelle suchen" />
            <List style={{ width: '100%', overflow: 'auto' }}>
                {siteList
                    .filter((site) => site.name.toLowerCase().includes(searchTerm.toLowerCase()))
                    .map((site) => (
                        <ListItem key={site.id} button onClick={() => handleSiteSelect(site)}>
                            {site.name}
                        </ListItem>
                    ))}
            </List>
        </Box>
    );
};

export default SiteSelect;
