import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Typography, Alert, Snackbar } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { QrReader } from '../components/QrReader';
import { addDeviceLocation } from '../utils/api';

function ScanView() {
  const pageLocation = useLocation();
  const queryParams = new URLSearchParams(pageLocation.search);
  const siteId = queryParams.get('site');
  const siteName = queryParams.get('name');
  const [result, setResult] = useState("");
  const [data, setData] = useState({"qrContent": "", "deviceName": "Bitte QR Code scannen", "readTime": 0, "tracked": false});
  const [location, setLocation] = useState({"lat": 0, "long": 0});
  const [isHandlingScan, setIsHandlingScan] = useState(false);
  const [error, setError] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  
  useEffect(() => {
    if (!!result) {
      setData(prevData => {
        if (result !== prevData.qrContent) {
          const deviceName = formatScan(result);
          if (!!deviceName){
            const audio = new Audio('/ping-82822.mp3');
            audio.volume = 1.0;
            audio.play();
            return {
              "qrContent": result, 
              "deviceName": deviceName,
              "readTime": new Date().getTime(), 
              "tracked": false
            }
          };
          return {
            "qrContent": "", 
            "deviceName": "Bitte gültigen QR Code scannen",
            "readTime": 0, 
            "tracked": false
          }
        }
        return prevData;
      });
    }
  }, [result]);

  useEffect(() => {
    if (navigator.geolocation) { 
      navigator.geolocation.getCurrentPosition(showPosition, handleError); 
    } else { 
      console.log("Geolocation is not supported by this browser."); 
    }
  
    function showPosition(position: Position) {
      setLocation({
        lat: position.coords.latitude,
        long: position.coords.longitude
      });
    }
  
    function handleError(error: GeolocationPositionError) {
      switch(error.code) {
        case error.PERMISSION_DENIED:
          alert("User denied the request for Geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          alert("The request to get user location timed out.");
          break;
        default:
          alert("An unknown error occurred.");
          break;
      }
    }
  }, []);

  const handleScan = async () => {
    setIsHandlingScan(true);
    try {
      if (data?.readTime !== 0) {
        await addDeviceLocation(data.qrContent, location, data.readTime, siteId || "", siteName || "");
        setData(prevData => ({ ...prevData, tracked: true }));
      }
    } catch (error) {
        if (error instanceof Error) {
          setError(error.toString());
        } else {
          setError("An unknown error occurred");
        }
        setShowAlert(true);
        setData({"qrContent": "Bitte QR Code scannen",  "deviceName": "","readTime": 0, "tracked": false})
        setResult("");
    }
    finally {
      setIsHandlingScan(false);
    }
  };

  const formatScan = (data: string) => {
    const [checkNum, deviceID, ...rest] = data.split(' ');
    const deviceName = rest.join(' ');
    return deviceName
  };

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" pt={2}>
        <Typography variant="h4">
          Baustelle: {siteName}
        </Typography>
      </Box>
        <QrReader
          onResult={(result, error) => {
            if (!!result) {
              console.log(result?.getText());
              setResult(result?.getText());
            }
            if (!!error) {
              // print error
            }
          }}
        />
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Typography variant="h5" align="center" style={{ color: data?.tracked ? 'green' : 'black' }} mb={2}>
          {data?.tracked && !isHandlingScan ? (
          <Box>
            <CheckCircleIcon />
            <strong>{data?.deviceName}</strong> wurde gespeichert. Scanne das nächste Gerät.
          </ Box>): data?.deviceName}
        </Typography>
        <Button variant="contained" color="primary" disabled={data?.tracked || data?.readTime === 0 || isHandlingScan} onClick={handleScan}>
          speichern
        </Button>
          <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
            <Alert severity="error" onClose={() => setShowAlert(false)}>
              {error}
            </Alert>
          </Snackbar>
      </Box>
    </>
  );
}

type Position = {
  coords: {
    latitude: number;
    longitude: number;
  };
};

export default ScanView;
