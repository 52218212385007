import { BACKEND_URL } from "./env";

async function fetchData(url: string, method: string, body?: any): Promise<any> {
    const options: RequestInit = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    let response = await fetch(url, options);

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
}

async function fetchDataWithRetry(url: string, method: string, body?: any): Promise<any> {
    const options: RequestInit = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    let response = await fetch(url, options);

    if (response.status === 403 || response.status === 401) {
        await refreshToken()
        response = await fetch(url, options);
    }
    if (!response.ok) {
        throw new Error(`HTTP error and retry failed! status: ${response.status}`);
    }

    return await response.json();
}

export async function addDeviceLocation(qrContent: string, location: coords, timeTracked: number, siteid: string, sitename: string): Promise<any> {
    const url = `${BACKEND_URL}/device`;
    const method = 'POST';
    const body = {
        "time_tracked": timeTracked,
        "n_coord": location.lat,
        "e_coord": location.long,
        "qr_content": qrContent,
        "site_id": siteid,
        "site_name": sitename
    };

    return await fetchDataWithRetry(url, method, body);
}

export async function fetchSites(): Promise<any> {
    const url = `${BACKEND_URL}/sites`;
    const method = 'GET';

    return await fetchDataWithRetry(url, method);
}

export async function checkAuth(): Promise<any> {
    const url = `${BACKEND_URL}/check-auth`;
    const method = 'GET';

    return await fetchDataWithRetry(url, method);
}

export async function refreshToken(): Promise<any> {
    const url = `${BACKEND_URL}/token`;
    const method = 'POST';

    return await fetchData(url, method);
}

export async function postLogin(username: string, password: string): Promise<any> {
    const url = `${BACKEND_URL}/login`;
    const method = 'POST';
    const body = { username, password };

    return await fetchData(url, method, body);
}

export async function postLogout(): Promise<any> {
    const url = `${BACKEND_URL}/logout`;
    const method = 'POST';

    return await fetchData(url, method);
}

type coords = {
    lat: number;
    long: number;
};