import React  from 'react';
import { Box, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';

const MyToolbar: React.FC = () => {
  const location = useLocation();
  const naviagte = useNavigate();
  
  const handleBack = () => {
    naviagte('/');
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ backgroundColor: 'red' }}>
        {location.pathname === '/scan' && (
          <IconButton edge="start" color="inherit" onClick={handleBack}>
            <ArrowBack />
          </IconButton>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" align="center">
            Geräte-Scanner
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default MyToolbar;