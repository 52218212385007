export const styles: any = {
    container: {
      width: '100%',
      paddingTop: '100%',
      overflow: 'hidden',
      position: 'relative',
    },
    video: {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'block',
      overflow: 'hidden',
      position: 'absolute',
      transform: undefined,
    },
  };