import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import { Button, TextField, Grid, Paper, Typography } from '@mui/material';

const Login: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const { login } = useAuth();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await login(username, password);
    };

    return (
        <Grid container alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
            <Paper style={{ padding: 20 }}>
                <Typography variant="h4" align="center" style={{ marginBottom: '20px' }}>Login</Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <TextField
                                label="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <Button type="submit" variant="contained" color="primary">Login</Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Grid>
    );
};

export default Login;