import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkAuth, postLogin, postLogout } from './utils/api';

interface AuthContextType {
  user: User | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  loading: boolean;
}

interface User {
username: string;
}

interface AuthProviderProps {
children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};


const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const authenticate = async () => {
      try {
        const data = await checkAuth();
        setUser({ username: data.username });
      } catch (error) {
        console.error('Authentication check failed:', error);
      } finally {
        setLoading(false);
      }
    };
  
    authenticate();
  }, []);

  const login = async (username: string, password: string): Promise<void> => {
    try {
        await postLogin(username, password);
        setUser({ username });
        navigate('/');
        
      } catch (error) {
        console.error('Login error:', error);
      }
  };

  const logout = async (): Promise<void> => {
    try {
        await postLogout();
        setUser(null);
        navigate('/login');
      } catch (error) {
        console.error('Logout error:', error);
      }
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>);
};

export { AuthContext, AuthProvider };