import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Login from './components/Login';
import ProtectedRoute from './ProtectedRoute';
import ScanView from './pages/ScanView';
import SiteSelect from './pages/SiteSelect';
import Toolbar from './components/Toolbar';


function App() {
  return (
      <Router>
        <div className="App">
          <header className="App-header">
            <Toolbar /> 
            <AuthProvider>
              <Routes>
                <Route path="/login" element={<Login/>} />
                <Route path="/" element={
                  <ProtectedRoute>
                    <SiteSelect />
                  </ProtectedRoute>
                } />
                <Route path="/scan" element={
                  <ProtectedRoute>
                    <ScanView />
                  </ProtectedRoute>} />
              </Routes>
            </AuthProvider> 
          </header>
        </div>
      </Router>
  );
}

export default App;
